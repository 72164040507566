<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout>
        <a-layout-content class="pc indexLayoutContent" style="padding: 0px">
          <div class="page mindex flex-col justify-start" style="height: auto">
            <top-a
              :kind="``"
              :topbarhide="topbarhide"
              :tb="tb"
              :w750="w750"
              :topbarOpacity="topbarOpacity"
              :lag="lag"
            ></top-a>

            <div class="group_4_0 flex-col mao40">
              <div class="g40 flex-col" :style="``">
                <div class="g40in flex-col">
                  <div class="gbar flex-col justify-between">
                    <div class="gtitles flex-col">
                      <span data-v-3f1e1d78="" class="text_13">
                        <img
                          src="https://cdn.moheweb.com/vdb/lweb/imgs/title40.png"
                          style="height: 80px"
                        />
                      </span>
                    </div>
                    <img
                      style="width: 200px; height: 1px; margin: 27px 0 0 0px"
                      class="image_2"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng77a881200aafe17d1ed232488c012d1b886eefc33dd61641a8ce8eb3885bf2f1"
                    />
                    <div class="gdes flex-col">
                      <div class="textline" style="margin-top: 10px">
                        趣野活动精彩不断，一起体验趣野无限。
                      </div>

                      <div class="btns flex-row justify-start">
                        <router-link :to="`/clublist/0/${lag}`">
                          <div
                            class="lbtn flex-row justify-center"
                            style="margin-right: 4rem"
                          >
                            <div class="lbtnin flex-row justify-between">
                              <span class="text-group_123">了解更多</span>

                              <img
                                data-v-3f1e1d78=""
                                referrerpolicy="no-referrer"
                                src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                class="image_186"
                              />
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>

                  <div class="g40list">
                    <div class="swiper sc40" style="opacity: 0">
                      <div class="swiper-wrapper">
                        <template v-for="(item, index) in clist40">
                          <div
                            :class="`swiper-slide`"
                            :key="index"
                            @click="
                              () => {
                                godetall(item.url, item.pname, item.kind, item.id);
                              }
                            "
                            
                          >
                            <div v-if="item.id != 0" class="gitem flex-col">
                              <div
                                class="gitemimg"
                                :style="`background-image: url(${
                                  baseUrl + item.imgurl
                                });`"
                              >
                                <div class="zmb zmbf" v-if="item.state == '0' ">敬请期待</div>
                                <div class="zmb" v-if="item.state == '1' ">招募中</div>
                                <div class="zmb zmbs" v-if="item.state == '2' ">报名截止</div>
                                <div class="zmb zmbs" v-if="item.state == '3' ">已结束</div>
                              </div>
                              <div class="giteminfo">
                                <div
                                  class="gitemtitle"
                                  v-html="item.pname"
                                ></div>
                                <div class="gitemdes" v-html="item.des"></div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>

                    <div class="swiper2bardiv">
                      <span class="sc2index sc2startindex">01</span>
                      <div class="sc2bardiv">
                        <div class="sc2bar"></div>
                      </div>
                      <span class="sc2index sc2finalindex">{{
                        clist40.length < 10
                          ? `0${clist40.length}`
                          : clist40.length
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="backimg"></div>
              </div>
            </div>

            <div class="group_4_1 flex-row justify-center mao41">
              <div class="group_4_1_in flex-col justify-start">
                <div
                  class="gbar flex-col justify-between"
                  style="margin-top: 120px"
                >
                  <div class="gtitles flex-col">
                    <span class="text_13" style="color: rgba(50, 50, 50, 1)">
                      <img
                        src="https://cdn.moheweb.com/vdb/lweb/imgs/title41.png"
                        style="height: 80px"
                      />
                    </span>
                    <router-link :to="`/clublist/1/${lag}`">
                      <div
                        class="lmore flex-row justify-center"
                        style="
                          position: absolute;
                          margin-top: 0px;
                          margin-bottom: 0px;
                          right: 0px;
                          top: 20px;
                        "
                      >
                        <div class="lmorein flex-row justify-between">
                          <span class="text-group_123">了解更多</span>
                          <img
                            referrerpolicy="no-referrer"
                            src="https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"
                            class="image_186 bjt"
                          />

                          <img
                            referrerpolicy="no-referrer"
                            src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                            class="image_186 wjt"
                            style="display: none"
                          />
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <img
                    style="width: 200px; height: 1px; margin: 27px 0 0 0px"
                    class="image_2"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga315fb212ffff37cf080d4749d9991c062b5d762169560cdffdd9667c04f91cf"
                  />
                  <div class="gdes flex-col">
                    <div
                      class="textline"
                      style="margin-top: 10px; color: rgba(50, 50, 50, 1)"
                    >
                      关注精彩活动，一起体验趣野无限
                    </div>
                  </div>
                </div>

                <div class="g41list">
                  <template v-for="(item, index) in clist41">
                    <div class="g41item" :key="index">
                      <div class="imglist">
                        <template v-for="(item2, index2) in item.imgary">
                          <div
                            :class="`item ${
                              item2.url == item.imgurl ? 'selected' : ''
                            }`"
                            :key="index2"
                            :style="`background-image:url(${
                              baseUrl + item2.url
                            })`"
                            @mouseover="
                              () => {
                                g41imghandleHover(index, item2.url);
                              }
                            "
                            @touchstart="
                              () => {
                                g41imghandleHover(index, item2.url);
                              }
                            "
                          ></div>
                        </template>
                      </div>

                      <div class="showimg">
                        <img :src="baseUrl + item.imgurl" />
                      </div>

                      <div class="iteminfo">
                        <div class="title" v-html="item.pname"></div>
                        <div class="des" v-html="item.des"></div>
                        <router-link
                          :to="`/mdetall/${item.kind}/${item.id}/${lag}`"
                        >
                          <div class="btn">详情回顾</div>
                        </router-link>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="backimg"></div>
            </div>

            <footer-a :kind="``" :lag="lag"></footer-a>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import {
  fetchClass,
  fetchCase,
  fetchFriend,
  fetchBase,
  fetchAwards,
} from "../api/index";
import FooterA from "../components/FooterA.vue";
import TopA from "../components/TopA.vue";
// const Base64 = require('js-base64').Base64
import Swiper, {
  FreeMode,
  Autoplay,
  Effect,
  Pagination,
  Mousewheel,
  Scrollbar
} from "swiper";
Swiper.use([FreeMode, Autoplay, Effect, Pagination, Mousewheel,Scrollbar]);
import "swiper/swiper-bundle.css";
import global from "../common";
import $ from "jquery";
export default {
  name: "index",
  components: {
    FooterA,
    TopA,
  },
  props: {
    msg: String,
  },
  watch: {
    $route(to, from) {
      // to.params为最新的参数
      // from.params为之前的参数
      console.log(to.params);
      this.id = to.params["id"];

      this.$el
        .querySelector(`.mao${this.id}`)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
  },
  data() {
    return {
      clist40: [],
      clist41: [],
      clist42: [],

      g41list: [
        {
          id: "1",
          kind: 41,
          title: "Rapha是骑行届的「颜值顶流」,&nbsp;没人反对吧？",
          des: "2020年后出生的一代人，他们在社交方面与前几代人有着很大的不同…",
          from: "ALL fields",
          jianjie:
            "这里写一段描述内容，这里写一段描述内容，这里写一段描述内容。这里写一段描述内容，这里写一段描述内容，这里写一段描述内容",
          photo:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng407ddf08f8701eab20f7f48a9b28a6e911834718a6050bb68d55b31ffc2aa6c2",
          pname: "ALL fields",
          addtime: "2023-08-31 20:00",
          imgs: [
            {
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf616a7b3e8d00d2341756dd81b1606488b19d2f435d5424f927f74320d40896c",
            },
            {
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/54325a55e7ad42df8180dd5c69ddea22_mergeImage.png",
            },
          ],
          url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf616a7b3e8d00d2341756dd81b1606488b19d2f435d5424f927f74320d40896c",
        },
        {
          id: "2",
          kind: 41,
          title: "Rapha是骑行届的「颜值顶流」,&nbsp;没人反对吧？",
          des: "2020年后出生的一代人，他们在社交方面与前几代人有着很大的不同…",
          from: "ALL fields",
          jianjie:
            "这里写一段描述内容，这里写一段描述内容，这里写一段描述内容。这里写一段描述内容，这里写一段描述内容，这里写一段描述内容",
          photo:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng407ddf08f8701eab20f7f48a9b28a6e911834718a6050bb68d55b31ffc2aa6c2",
          pname: "ALL fields",
          addtime: "2023-08-31 20:00",
          imgs: [
            {
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf616a7b3e8d00d2341756dd81b1606488b19d2f435d5424f927f74320d40896c",
            },
            {
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/54325a55e7ad42df8180dd5c69ddea22_mergeImage.png",
            },
          ],
          url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf616a7b3e8d00d2341756dd81b1606488b19d2f435d5424f927f74320d40896c",
        },
        {
          id: "3",
          kind: 41,
          title: "Rapha是骑行届的「颜值顶流」,&nbsp;没人反对吧？",
          des: "2020年后出生的一代人，他们在社交方面与前几代人有着很大的不同…",
          from: "ALL fields",
          jianjie:
            "这里写一段描述内容，这里写一段描述内容，这里写一段描述内容。这里写一段描述内容，这里写一段描述内容，这里写一段描述内容",
          photo:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng407ddf08f8701eab20f7f48a9b28a6e911834718a6050bb68d55b31ffc2aa6c2",
          pname: "ALL fields",
          addtime: "2023-08-31 20:00",
          imgs: [
            {
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf616a7b3e8d00d2341756dd81b1606488b19d2f435d5424f927f74320d40896c",
            },
            {
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/54325a55e7ad42df8180dd5c69ddea22_mergeImage.png",
            },
          ],
          url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf616a7b3e8d00d2341756dd81b1606488b19d2f435d5424f927f74320d40896c",
        },
        {
          id: "4",
          kind: 41,
          title: "Rapha是骑行届的「颜值顶流」,&nbsp;没人反对吧？",
          des: "2020年后出生的一代人，他们在社交方面与前几代人有着很大的不同…",
          from: "ALL fields",
          jianjie:
            "这里写一段描述内容，这里写一段描述内容，这里写一段描述内容。这里写一段描述内容，这里写一段描述内容，这里写一段描述内容",
          photo:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng407ddf08f8701eab20f7f48a9b28a6e911834718a6050bb68d55b31ffc2aa6c2",
          pname: "ALL fields",
          addtime: "2023-08-31 20:00",
          imgs: [
            {
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf616a7b3e8d00d2341756dd81b1606488b19d2f435d5424f927f74320d40896c",
            },
            {
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/54325a55e7ad42df8180dd5c69ddea22_mergeImage.png",
            },
          ],
          url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf616a7b3e8d00d2341756dd81b1606488b19d2f435d5424f927f74320d40896c",
        },
      ],

      g00list: [
        {
          title: "趣野网站正式上线啦！",
          des: "2020年后出生的一代人，他们在社交方面",
          id: "1",
          kind: 40,
          url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf616a7b3e8d00d2341756dd81b1606488b19d2f435d5424f927f74320d40896c",
        },
        {
          title: "趣野网站正式上线啦！",
          des: "2020年后出生的一代人，他们在社交方面",
          id: "2",
          kind: 40,
          url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng1dd60df3ce3df5bbbbc585073cdc5ecfcb1aa7a1fbcc8ec7627ac9c641bc412f",
        },
        {
          title: "趣野网站正式上线啦！",
          des: "2020年后出生的一代人，他们在社交方面",
          id: "3",
          kind: 40,
          url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf58e48fb4d2b3a72b5ba3f8acd537d968c88e6e6564d263e1060f32e48d52210",
        },
        {
          title: "趣野网站正式上线啦！",
          des: "2020年后出生的一代人，他们在社交方面",
          id: "4",
          kind: 40,
          url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf616a7b3e8d00d2341756dd81b1606488b19d2f435d5424f927f74320d40896c",
        },
        {
          title: "趣野网站正式上线啦！",
          des: "2020年后出生的一代人，他们在社交方面",
          id: "5",
          kind: 40,
          url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf58e48fb4d2b3a72b5ba3f8acd537d968c88e6e6564d263e1060f32e48d52210",
        },
        {
          title: "趣野网站正式上线啦！",
          des: "2020年后出生的一代人，他们在社交方面",
          id: "6",
          kind: 40,
          url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf616a7b3e8d00d2341756dd81b1606488b19d2f435d5424f927f74320d40896c",
        },
        {
          title: "",
          des: "",
          id: "0",
          url: "",
        },
      ],

      pageHeight: 0,
      tbanimate: "down",
      winHeight: 0,
      topbarhide: 0,
      id: "0",
      lag: "",
      w750: 0,
      m_group_2_height: 0,
      basedata: [],
      frienddata: [],
      frienddata2: [],
      ppswiperindex: 0,
      nopplistshow: 0,
      baseUrl: global.baseUrl,
      thisclass1: 68,
      thisclass2: "",

      classlist: [],

      class2list: [],

      swiper: null,

      swiper0: null,

      swiper1: null,

      swiper2: null,

      swiper3: null,

      swiper03: null,

      selectIndex: 0,

      barclass: "closethis",
      pageWidth: 1920,
      topbarOpacity: 0,
      gtitle: "整合内容营销&nbsp;+&nbsp;智能数据平台&nbsp;+&nbsp;奢侈品营销",
      gdesc:
        "IPmotion（上海派志）作为一类以独特创意DNA驱动的公司，而区别于一般的“广告创意公司”。<br />成立十多年来，IPmotion（上海派志）在人工智能、IT电子、医药、汽车等垂直领域积累了丰富的经验案例，并助力企业、品牌的营销转型和数字转型的全过程。为全球500强与国内行业领先品牌，如：智能科技类企业：世界人工智能大会、世界制造业大会、华为、远景集团、医药类企业：阿斯利康、基石药业、BD医疗、奢侈品类企业：香奈儿、MHD、帕玛强尼等提供全案创意营销代理服务和线下解决方案。",
      mkinds: [],
      ppindex: -1,
      pplist: [],
      bselectindex: -1,

      sectionindex: 0,
      sections: [],
      data: [],
      dataloading: 0,
      pageSize: 12,
      query: {
        class1: [],
        class2: [],
      },
      alive: true,
      adata: [],
      awardsindex: 0,

      opy1: 0,
      opy2: 0,
      opy3: 0,
      opy4: 0,
      opy5: 0,
      opy6: 0,
      opy7: 0,
      opy8: 0,

      group1h: 0,
      group2h: 0,
      group3h: 0,
      group4h: 0,
      group5h: 0,
      group6h: 0,
      group7h: 0,

      tb: true,
      masktop: 0,
      masksize: 0,
      g8top: 0,
      g8left: 0,

      nowdate: '',
    };
  },

  activated() {
    let that = this;
    console.log("onActivated");
    let scrollvalue = global.getIndexscroll();
    if (scrollvalue > 0) {
      $(".main").scrollTop(scrollvalue);
    }

    if (scrollvalue > 400) {
      this.topbarOpacity = (scrollvalue - 400) / 200;
      if (this.topbarOpacity > 1) {
        this.topbarOpacity = 1;
      }
    } else {
      this.topbarOpacity = 0;
    }

    that.resizepage();

    setTimeout(() => {
      let classtext = global.getIndexDclass();
      // console.log("classtext:", classtext);
      if (classtext != "") {
        that.goDiv(classtext, "");
      }
      // let video0 = document.getElementById("video0");
      // video0.play();
    }, 1000);
    this.alive = true;
    this.handleScroll;
  },
  deactivated() {
    console.log("deactivated:");
    this.alive = false;
  },

  mounted() {
    let that = this;

    let id = this.$route.params.id;

    let lag = this.$route.params.lag;

    if (id) {
      this.id = id;
      setTimeout(() => {
        this.$el
          .querySelector(`.mao${this.id}`)
          .scrollIntoView({ block: "start", behavior: "smooth" });
      }, 500);
    }

    if (lag) {
      this.lag = lag;
    }

    console.log("this.lag:", this.lag);

    if (this.lag == "en") {
      this.sections = this.ensections;
    } else {
      this.sections = this.chsections;
    }
    global.setIndexDclass("");
    global.setIndexscroll(0);
    that.handleScroll;
    that.resizepage();
    this.$el.addEventListener("scroll", that.handleScroll, true);
    // window.addEventListener("resize", that.handleResize, true);

    window.onresize = ()=>{
      that.handleResize();
    }

    setTimeout(() => {
      $(document).on("click", ".closesw", function () {
        $(".showppdiv").fadeTo(300, 0, () => {
          $(".showppdiv").css({ left: "-100%" });
        });
      });
      $(document).on("click", ".closesw3", function () {
        $(".showawardsdiv").fadeTo(300, 0, () => {
          $(".showawardsdiv").css({ left: "-100%" });
        });
      });
    }, 1000);

    this.fetch40();
    this.fetch41();

    this.nowdate = this.getNowDate();
  },
  beforeDestroy() {
    window.onresize = null;
    console.log("index beforeDestroy");
  },
  beforeCreate() {
    let that = this;
  },
  methods: {

    getNowDate() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      month = month > 9 ? month : "0" + month;
      day = day < 10 ? "0" + day : day;
      var today = year + "/" + month + "/" + day;
      return today;
    },

    fetch40(
      params = { show: 1, hot: 1, kind: "40", pagesize: 20, query: this.query }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist40 = res.results.list;
        }
        console.log("clist40:", that.clist40);

        that.myswiper2();
      });
    },
    fetch41(
      params = { show: 1, hot: 1, kind: "41", pagesize: 4, query: this.query }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist41 = res.results.list;
        }
        console.log("clist41:", that.clist41);
      });
    },

    // godetall(kind, id) {
    //   this.$router.push(`/mdetall/${kind}/${id}/${this.lag}`);
    // },

    godetall(link, title, kind, id) {
      // alert(title+link);
      if (link != "") {
        window.location.href = link;
      } else {
        if (title != "") {
          this.$router.push(`/mdetall/${kind}/${id}/${this.lag}`);
        }
      }
    },

    g41imghandleHover(index, url) {
      console.log("g41:", index, url);
      this.clist41[index].imgurl = url;
    },
    swiper1Prev() {
      this.swiper03.slidePrev(500);
    },
    swiper1Next() {
      this.swiper03.slideNext(500);
    },

    handleScroll(e) {
      // console.log("handleScroll");

      if (this.alive) {
        const winHeight =
          e.target.scrollTop || document.documentElement.scrollTop;
        // console.log("e.target.scrollTop:",e.target.scrollTop);
        global.setIndexscroll(winHeight);

        let group1h = $(".group_1").height();
        let group2h = $(".group_2").height();
        let group3h = $(".group_3").height();
        let group4h = $(".group_4").height();
        let group5h = $(".group_5").height();
        let group6h = $(".group_6").height();
        let group7h = $(".group_7").height();
        this.group1h = group1h;
        this.group2h = group2h;
        this.group3h = group3h;
        this.group4h = group4h;
        this.group5h = group5h;
        this.group6h = group6h;
        this.group7h = group7h;

        this.tb = true;

        if (
          this.winHeight >
          this.pageHeight +
            this.pageHeight +
            this.pageHeight +
            group2h +
            group4h +
            group6h +
            2000 +
            2000
        ) {
          console.log("down");
          this.tbanimate = "up";
          // $(".topbar").stop(true).animate({ top: "-140px" }, 100);
          $(".topbar").removeClass("downtopbar");
          $(".topbar").addClass("uptopbar");
        }

        if (winHeight > 120) {
          if (this.winHeight > winHeight && this.tbanimate != "down") {
            this.tbanimate = "down";
            // $(".topbar").stop(true).animate({ top: "0px" }, 100);
            $(".topbar").removeClass("uptopbar");
            $(".topbar").addClass("downtopbar");
          } else if (this.winHeight < winHeight && this.tbanimate != "up") {
            this.tbanimate = "up";
            // $(".topbar").stop(true).animate({ top: "-140px" }, 100);
            $(".topbar").removeClass("downtopbar");
            $(".topbar").addClass("uptopbar");
          }
        }

        this.winHeight = winHeight;
        if (winHeight > 400) {
          this.topbarOpacity = (winHeight - 400) / 200;
          if (this.topbarOpacity > 1) {
            this.topbarOpacity = 1;
          }
        } else {
          this.topbarOpacity = 0;
        }
      }
    },

    myswiper2() {
      let that = this;

      setTimeout(() => {
        this.swiper2 = new Swiper(".sc40", {
          slidesPerView: 3,
          // slidesPerView: 2,
          // loop: true,
          // centeredSlides: true,
          spaceBetween: 20,
          freeMode: true,
          // // grabCursor: true,
          // // pagination: {
          // //   el: ".swiper-pagination",
          // //   clickable: true,
          // // },

          // observer: true, //修改swiper自己或子元素时，自动初始化swiper
          // observeParents: true, //修改swiper的父元素时，自动初始化swiper
          // observeSlideChildren: true,
          on: {
            transitionEnd: function () {
              // that.selectIndex = this.activeIndex;

              // console.log("this", this);

              // console.log("this.selectIndex:", this.realIndex);
              let w =
                parseInt(
                  ((parseInt(this.realIndex) + 4) / that.clist40.length) * 1000
                ) / 10;
              // console.log("www:", w);
              $(".sc2bar").css({ width: w + "%" });
            },
            init: function (swiper) {
              let w = parseInt((4 / that.clist40.length) * 1000) / 10;
              $(".sc2bar").css({ width: w + "%" });
              $(".sc40").css({ opacity: "1" });
            },
          },
        });

        this.swiper03 = new Swiper(".sc03", {
          slidesPerView: 1,
          // slidesPerView: 2,
          // loop: true,
          // centeredSlides: true,
          spaceBetween: 0,
          // freeMode: true,
          // grabCursor: true,

          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          observeSlideChildren: true,
          pagination: {
            el: ".sp03",
            clickable: true,
          },

          on: {
            transitionEnd: function () {},
            init: function (swiper) {},
          },
        });
      }, 500);
    },

    windowopen(url) {
      if (url != "") {
        window.open(url);
      }
    },
    fetch(
      params = { show: 1, hot: 1, pagesize: this.pageSize, query: this.query }
    ) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      fetchCase(params).then((res) => {
        // console.log("fetchCase:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        if (res.results.list) {
          that.data = res.results.list;
          that.nopplistshow = 0;
        } else {
          that.nopplistshow = 1;
        }
        that.dataloading = 0;
        that.pagination = pagination;
        // console.log("data:", that.data);
        // setTimeout(() => {
        //   that.swiper.update();
        // }, 100);
      });
    },
    fetchf(params = { show: 1, pagesize: 1000 }) {
      let that = this;
      this.loading = true;

      fetchFriend(params).then((res) => {
        // console.log("fetchFriend:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        that.frienddata = res.results.list;

        let frienddata2 = [];

        let lindex = 0;
        res.results.list.map((item, index) => {
          if (index % 4 == 0) {
            frienddata2[lindex] = [];
          }
          frienddata2[lindex].push(item);
          if (index % 4 == 3) {
            lindex++;
          }
        });
        that.frienddata2 = frienddata2;

        if (that.w750 == 0) {
        } else {
        }
      });
    },
    fetcha(params = { show: 1, hot: 1, pagesize: this.pageSize }) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      fetchAwards(params).then((res) => {
        console.log("fetchAwards:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;

        if (res.results.list) {
          that.adata = res.results.list;
        } else {
        }
        that.pagination = pagination;
        // console.log("data:", that.data);
        // setTimeout(() => {
        //   that.swiper3.update();
        // }, 100);
      });
    },

    fetchb(params = {}) {
      let that = this;
      this.loading = true;

      fetchBase(params).then((res) => {
        // console.log("fetchBase:", res.results);
        that.loading = false;
        that.basedata = res.results;

        that.blinks[0].title = res.results.t0;
        that.blinks[1].title = res.results.t1;
        that.blinks[2].title = res.results.t2;
        that.blinks[3].title = res.results.t3;
        that.blinks[4].title = res.results.t4;
        that.blinks[5].title = res.results.t5;

        if (that.lag == "en") {
          that.blinks[0].title = res.results.ent0;
          that.blinks[1].title = res.results.ent1;
          that.blinks[2].title = res.results.ent2;
          that.blinks[3].title = res.results.ent3;
          that.blinks[4].title = res.results.ent4;
          that.blinks[5].title = res.results.ent5;

          that.basedata.t0 = res.results.ent0;
          that.basedata.t1 = res.results.ent1;
          that.basedata.t2 = res.results.ent2;
          that.basedata.t3 = res.results.ent3;
          that.basedata.t4 = res.results.ent4;
          that.basedata.t45 = res.results.ent45;
          that.basedata.t5 = res.results.ent5;
          that.basedata.t6 = res.results.ent6;
          that.basedata.t7 = res.results.ent7;
          that.basedata.t8 = res.results.ent8;
          that.basedata.t9 = res.results.ent9;
          that.basedata.t10 = res.results.ent10;

          that.basedata.tel = res.results.entel;
          that.basedata.adr = res.results.enadr;
          that.basedata.email = res.results.enemail;
          that.basedata.mtitle = res.results.enmtitle;
          that.basedata.mcontent = res.results.enmcontent;
          that.basedata.aititle = res.results.enaititle;
          that.basedata.aicontent = res.results.enaicontent;
          that.basedata.aicontent0 = res.results.enaicontent0;
          that.basedata.aicontent1 = res.results.enaicontent1;
          that.basedata.aicontent2 = res.results.enaicontent2;
          that.basedata.aicontent3 = res.results.enaicontent3;
          that.basedata.aicontent4 = res.results.enaicontent4;
        }
      });
    },

    selectclass2(id) {
      this.nopplistshow = 0;
      this.dataloading = 1;
      this.thisclass2 = id;
      // console.log(id);
      this.query = {
        class1: this.thisclass1,
        class2: [id],
      };
      this.data = [];
      setTimeout(() => {
        this.fetch();
      }, 500);
    },
    getClassList() {
      const that = this;
      fetchClass().then((res) => {
        that.classlist = res.results.list;

        // console.log("classlist:", that.classlist);

        that.class2list = this.classlist.filter((it) => {
          return this.thisclass1 == it.id;
        })[0]["list"];

        // console.log("class2list:", that.class2list);

        let mkinds = [];

        that.class2list.map((item) => {
          let cname = item.cname;
          if (that.lag == "en") {
            cname = item.encname;
          }
          mkinds.push({ name: cname, id: item.id });
        });

        that.mkinds = mkinds;

        console.log("mkinds:", this.mkinds);
      });
    },

    gowebsite(url) {
      window.open(url);
    },

    openBardiv() {
      this.barclass = "openthis";
    },
    closeBardiv() {
      this.barclass = "closethis";
    },
    handleResize() {
      this.resizepage();
    },


    resizeViewport() {
      let that = this;
      let bl = window.innerHeight / window.innerWidth;
      if (bl > 1.2) {
        this.sectionindex = 0;
        this.m_group_2_height = this.pageHeight;
        this.w750 = 1;
        if (bl > 1.5) {
          $("meta[name=viewport]").attr(
            "content",
            "width=750,user-scalable=no"
          );
        } else {
          $("meta[name=viewport]").attr(
            "content",
            "width=1280,user-scalable=no"
          );
        }
        this.$router.replace(`/clubm/${this.id}/${this.lag}`);
        // window.location.href=`#/clubm/${this.id}/${this.lag}`;
        // setTimeout(() => {
        //   window.location.reload();
        // }, 100);
      } else {
        $("meta[name=viewport]").attr("content", "width=1280,user-scalable=no");
        this.m_group_2_height = 0;
        this.w750 = 0;
        // window.location.href = `#/index/${this.lag}`;
      }
    },



    resizepage() {
      let that = this;
      this.pageWidth = window.innerWidth;
      let pageHeight = window.innerHeight;

      this.pageHeight = pageHeight;

      $(".windowheight").css({ height: `${pageHeight}px` });
      setTimeout(() => {
        $(".windowheight").css({ height: `${this.pageHeight}px` });
      }, 1000);
      // // if (window.innerWidth > 1920) {
      // //   this.pageWidth = 1920;
      // // }
      // if (window.innerWidth <= 750) {
      //   this.sectionindex = 0;
      //   this.m_group_2_height = pageHeight;
      //   this.w750 = 1;
      //   window.location.href = `#/clubm/${this.id}/${this.lag}`;
      // } else {
      //   this.m_group_2_height = 0;
      //   this.w750 = 0;
      // }

      this.resizeViewport();

      $("html").css({ fontSize: this.pageWidth / 192 });

      if (that.w750 == 0) {
        that.myswiper2();
      } else {
      }
    },
    changelag(lag) {
      if (lag == "en") {
        this.$router.push("index/en");
        setTimeout(() => {
          location.reload();
        }, 100);
      } else {
        this.$router.push("/index");
        setTimeout(() => {
          location.reload();
        }, 100);
      }
    },
    goDiv(classtext, url) {
      this.barclass = "closethis";
      if (url != "") {
        global.setIndexscroll(0);
        if (this.lag == "en") {
          url = `/${url}/en`;
        }
        this.$router.push(url);
        return false;
      }
      this.$el
        .querySelector(`.${classtext}`)
        .scrollIntoView({ block: "start", behavior: "smooth" });

      global.setIndexDclass("");
    },

    goIndex() {
      this.$router.push(`/indexl`);
    },

    goSelect(index) {
      this.$router.push(`/sindex/${index}/${this.lag}`);
    },

    goMindex(id2) {
      let url = "";
      let id = this.id;
      if (id == 0) {
        url = "fengxiang";
      }
      if (id == 1) {
        url = "huoban";
      }
      if (id == 2) {
        url = "";
      }
      if (id == 3) {
        url = "show";
      }
      if (id == 4) {
        url = "club";
      }

      if (url != "") {
        this.$router.push(`/${url}/${id2}/${this.lag}`);
      }
    },

    blinkshandleHover(index) {
      this.bselectindex = index;
      // console.log(this.bselectindex);
    },
    sectionhandleHover(index) {
      if (this.w750 == 0) this.sectionindex = index;
    },
    blinkshandleLeave() {
      this.bselectindex = -1;
    },
    pphandleHover(index) {
      this.ppindex = index;
    },
    pphandleLeave() {},

    awardshandleHover(index) {
      this.awardsindex = index;
    },
    awardshandleLeave() {
      this.awardsindex = -1;
    },
  },
};
</script>


<style src="../assets/css/common.css" />
<style src="../assets/css/indexl.css" />
<style src="../assets/css/indexl1280.css" />

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
